import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PlaceholderState {
	definitions: { [key: string]: string };
}

const initialState: PlaceholderState = {
	definitions: {},
};

export const placeholderSlice = createSlice({
	name: 'PlaceholderSlice',
	initialState,
	reducers: {
		setPlaceholderDefinitions: (state, action: PayloadAction<MetaArea>) => {
			const metaArea = action.payload;
			let definitions = {};
			metaArea.items?.forEach((metaAreaContent) => {
				const defs = metaAreaContent.content?.placeholderDefinitions?.productDefinitions;
				if (defs) {
					// use keys toLower
					Object.keys(defs).forEach((key) => {
						definitions[key.toLowerCase()] = defs[key];
					});
				}
			});

			return {
				...state,
				definitions,
			};
		},
	},
});

export const { setPlaceholderDefinitions } = placeholderSlice.actions;
export default placeholderSlice.reducer;
