import { SnapScroller } from '@almbrand/snapscroller';
import classNames from 'classnames';
import { PageTemplate } from 'components';
import { IsPackageComponentContext } from 'context/IsPackageComponentContext';
import { useFetchCustomer, usePageParam } from 'hooks';
import { memo, StrictMode, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModal } from 'store/slices/modalSlice';
import { addPage, incrementIndex, resetIndex, updateLast } from 'store/slices/pageListSlice';
import { isPageHidden } from '../../../services/DependencyService';
import { isPackageStep } from '../../../services/PackageService';
import pageTemplateStyle from '../PageTemplate/PageTemplate.module.scss';
import styles from './MultiplePageTemplate.module.scss';

interface ActiveContentArea {
	name: keyof ContentAreas;
	contentData: ContentData;
}

export interface MultiplePageTemplateProps {
	pageModel: PageModel;
}

export const MultiplePageTemplate: React.FC<MultiplePageTemplateProps> = memo(({ pageModel }) => {
	const [navigationIndex, setNavigationIndex] = useState(0);

	const pages = useAppSelector((state) => state.pages);
	const { contentArea, loadNewPage } = pages;

	const dispatch = useAppDispatch();

	const modal = useAppSelector((state) => state.modal);

	const { setPageParam, getPageParam } = usePageParam();

	const { setIsVisible, isVisible } = useContext(IsPackageComponentContext);

	const [skipHiddenPage, setSkipHiddenPage] = useState(0);

	useFetchCustomer();

	useEffect(() => {
		// Get the active content area
		const activeContentArea = getActiveContentArea();
		if (!activeContentArea) return; // Early return if no active content area

		const { name, contentData } = activeContentArea;
		const { sectionIndex, pageIndex } = contentData ?? {};

		const page = pageModel[name]?.items[sectionIndex]?.content.steps.items[pageIndex]?.content;
		const sectionLength = pageModel[name]?.items.length;
		const pageLength = pageModel[name]?.items[sectionIndex]?.content.steps.items.length;

		if (page) {
			// initial hidden by dependency rule
			const isHiddenInitially = isPageHidden(page);

			if (isHiddenInitially && skipHiddenPage !== page.contentId) {
				// causes this effect to run again
				setSkipHiddenPage(page.contentId);
			}

			// add index, section and area to page
			page.pageIndex = pageIndex;
			page.sectionIndex = sectionIndex;
			page.area = name;

			dispatch(
				addPage({
					contentArea: name,
					page: page,
				})
			);
			if (!isHiddenInitially) {
				setPageParam(page.contentId.toString().replace('/', ''));
			}

			//Check if last page/step
			if (pageIndex === pageLength - 1) {
				dispatch(incrementIndex({ contentArea: name, whatIndex: 'section' }));
				dispatch(resetIndex({ contentArea: name, whatIndex: 'page' }));
				//Check if it's the last section in contentArea
				if (sectionIndex === sectionLength - 1) {
					dispatch(updateLast({ contentArea: name, lastPage: true }));
					dispatch(updateLast({ contentArea: name, lastSection: true }));
				}
			}
		}
	}, [loadNewPage, skipHiddenPage]);

	//update SnapScroller index
	useEffect(() => {
		const indexOfActivePage = getIndexOfActivePage();
		if (indexOfActivePage !== -1) {
			setNavigationIndex(indexOfActivePage);
		}
		// when page changes always close open modals
		if (modal.displayModal) {
			dispatch(setModal({ displayModal: false }));
		}
	}, [getPageParam()]);

	// ----------------------FOR TESTING PURPOSES---------------------------------//
	// This is a test for get the correct value of scroll delay
	// ---------------------------------------------------------------------------//
	const [searchParams] = useSearchParams();
	const getDelayParam = () => searchParams.get('scrollDelay');
	// ---------------------------------------------------------------------------//

	const getActiveContentArea = (): ActiveContentArea | undefined => {
		return Object.keys(contentArea)
			.map((key: keyof ContentAreas) => {
				const { pageLast, sectionLast, sectionIndex } = contentArea[key].content;
				// Skip content areas where both sectionLast and pageLast are true
				if (sectionLast && pageLast) return undefined;

				const items = pageModel[key]?.items;

				// Ensure there are valid items in the page model and the content steps are available
				if (items?.length && items[sectionIndex]?.content.steps.items.length) {
					return {
						name: key,
						contentData: contentArea[key].content,
					};
				}

				return undefined;
			})
			.find((contentAreaObject) => contentAreaObject !== undefined) as ActiveContentArea | undefined;
	};

	const mapActivePages = () => {
		return collectActivePages().map((page, index) => (
			<PageTemplate
				key={page.contentId}
				pageData={page}
			/>
		));
	};

	const collectActivePages = () => {
		const activePages = [] as ActivePage[];
		Object.keys(contentArea).forEach((key: keyof ContentAreas, index) =>
			activePages.push(...contentArea[key].content.activePages)
		);

		return activePages;
	};

	const getIndexOfActivePage = (): number => {
		const page = collectActivePages().find((page) => page.contentId === parseInt(getPageParam()));
		return page?.navigationIndex;
	};

	return (
		<StrictMode>
			<div
				className={classNames(
					styles.MultiplePageTemplate,
					isVisible && styles['MultiplePageTemplate--full-height']
				)}
			>
				<SnapScroller
					activeElement={navigationIndex}
					targetClass={pageTemplateStyle.PageTemplate}
					delay={parseInt(getDelayParam()) || Infinity}
					onIndexChange={(index) => {
						const activePages = collectActivePages();
						//Updates the URL when scrolling
						setPageParam(activePages[index].contentId.toString().replace('/', ''));

						//Delays adding class on scroll between PackageComponent and other components
						setTimeout(() => {
							setIsVisible(isPackageStep(activePages[index]));
						}, 100);
					}}
				>
					{mapActivePages()}
				</SnapScroller>
			</div>
		</StrictMode>
	);
});

MultiplePageTemplate.displayName = 'MultiplePageTemplate';
