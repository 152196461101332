/* eslint-disable */
// [
// 	{
// 		cover_group_id: 'PT_PT_PTO_1',
// 		premium: 653,
// 	},
// 	{
// 		cover_group_id: 'PT_PT_PTO_2',
// 		premium: 335,
// 	},
// 	{
// 		cover_group_id: 'PT_PT_PTO_3',
// 		premium: 167,
// 	},
// 	{
// 		cover_group_id: 'PT_PT_PTO_4',
// 		premium: 561,
// 	},
// 	{
// 		cover_group_id: 'PT_PT_PTO_96',
// 		premium: 18.88,
// 	},
// 	{
// 		cover_group_id: 'affinity_risk',
// 		premium: 0,
// 	},
// 	{
// 		cover_group_id: 'price_adj_risk',
// 		premium: 0,
// 	},
// ];

export const mockProductInformation = {
	title: 'Produktinformation og betingelser',
	content: `<p style="margin-bottom: 1rem">
					I betingelserne kan du se de præcise dækninger for den nyeste udgave af forsikringen.
					<a style="color: #1C40FF; text-decoration: underline" target="_blank" href="https://core01.codan.dk/forsikringer/rejseforsikring/">Se betingelser</a>
				</p>
				<p style="margin-bottom: 96px">
					Produktinformation kan du bruge til at sammenligne forsikringer på tværs af forsikringsselskaber.
					<a style="color: #1C40FF, text-decoration: underline" target="_blank" href="https://core01.codan.dk/forsikringer/rejseforsikring/">Se produktinformation</a>
				</p>`,
	subtitle: 'Om produktet',
};

const subtitle = 'Om dækningen';
const content = `
				<p style="margin-bottom: 1rem">
					I betingelserne kan du se de præcise dækninger for den nyeste udgave af forsikringen.
					<a style="color: #1C40FF; text-decoration: underline" target="_blank" href="https://core01.codan.dk/forsikringer/rejseforsikring/">Se betingelser</a>
				</p>
				<p style="margin-bottom: 96px">
					Produktinformation kan du bruge til at sammenligne forsikringer på tværs af forsikringsselskaber.
					<a style="color: #1C40FF, text-decoration: underline" target="_blank" href="https://core01.codan.dk/forsikringer/rejseforsikring/">Se produktinformation</a>
				</p>`;

const coverages: Map<string, CoverGroup> = new Map([
	[
		'Sygdom, tilskadekost og hjemtransport',
		{
			cover_group_id: 'PT_PT_PTO_1',
			fields: [],
			coverName: 'Sygdom, tilskadekost og hjemtransport',
			coverDescription: {
				title: 'Sygdom, tilskadekost og hjemtransport',
				subtitle,
				content,
			},
		},
	],
	[
		'Kompensation for ødelagt rejse',
		{
			cover_group_id: 'PT_PT_PTO_1',
			fields: [],
			coverName: 'Kompensation for ødelagt rejse',
			coverDescription: {
				title: 'Kompensation for ødelagt rejse',
				subtitle,
				content,
			},
		},
	],
	[
		'Forsinket bagage',
		{
			cover_group_id: 'PT_PT_PTO_1',
			fields: [],
			coverName: 'Forsinket bagage',
			coverDescription: {
				title: 'Forsinket bagage',
				subtitle,
				content,
			},
		},
	],
	[
		'Forsinkelse',
		{
			cover_group_id: 'PT_PT_PTO_1',
			fields: [],
			coverName: 'Forsinkelse',
			coverDescription: {
				title: 'Forsinkelse',
				subtitle,
				content,
			},
		},
	],
	[
		'Bilferie',
		{
			cover_group_id: 'PT_PT_PTO_1',
			fields: [],
			coverName: 'Bilferie',
			coverDescription: {
				title: 'Bilferie',
				subtitle,
				content,
			},
		},
	],
	[
		'Evakuering',
		{
			cover_group_id: 'PT_PT_PTO_1',
			fields: [],
			coverName: 'Evakuering',
			coverDescription: {
				title: 'Evakuering',
				subtitle,
				content,
			},
		},
	],

	[
		'Afbestilling',
		{
			cover_group_id: 'PT_PT_PTO_4',
			fields: [],
			coverName: 'Afbestilling',
			coverDescription: {
				title: 'Afbestilling',
				subtitle,
				content,
			},
		},
	],
	[
		'Skitillæg',
		{
			cover_group_id: 'PT_PT_PTO_2',
			fields: [],
			coverName: 'Skitillæg',
			coverDescription: {
				title: 'Skitillæg',
				subtitle,
				content,
			},
		},
	],
	[
		'Bagage, Ansvar og Retshjælp',
		{
			cover_group_id: 'PT_PT_PTO_3',
			fields: [],
			coverName: 'Bagage, Ansvar og Retshjælp',
			coverDescription: {
				title: 'Bagage, Ansvar og Retshjælp',
				subtitle,
				content,
			},
		},
	],
]);

export const mock3PackagesMap: Map<string, PackageConfig> = new Map([
	[
		'Basis',
		{
			isPrimaryPackage: false,
			discountText: '10% online rabat -',
			coverages: [
				{ ...coverages.get('Sygdom, tilskadekost og hjemtransport'), isActive: true },
				{ ...coverages.get('Kompensation for ødelagt rejse'), isActive: true },
				{ ...coverages.get('Forsinket bagage'), isActive: true },
				{ ...coverages.get('Forsinkelse'), isActive: true },
				{ ...coverages.get('Bilferie'), isActive: true },
				{ ...coverages.get('Evakuering'), isActive: true },
				{ ...coverages.get('Afbestilling'), isActive: false },
				{ ...coverages.get('Skitillæg'), isActive: false },
				{ ...coverages.get('Bagage, Ansvar og Retshjælp'), isActive: false },
			],
		},
	],
	[
		'Standard',
		{
			isPrimaryPackage: true,
			discountText: '10% online rabat -',
			coverages: [
				{ ...coverages.get('Sygdom, tilskadekost og hjemtransport'), isActive: true },
				{ ...coverages.get('Kompensation for ødelagt rejse'), isActive: true },
				{ ...coverages.get('Forsinket bagage'), isActive: true },
				{ ...coverages.get('Forsinkelse'), isActive: true },
				{ ...coverages.get('Bilferie'), isActive: true },
				{ ...coverages.get('Evakuering'), isActive: true },
				{ ...coverages.get('Afbestilling'), isActive: true },
				{ ...coverages.get('Skitillæg'), isActive: false },
				{ ...coverages.get('Bagage, Ansvar og Retshjælp'), isActive: false },
			],
		},
	],
	[
		'Premium',
		{
			isPrimaryPackage: false,
			discountText: '10% online rabat -',
			coverages: [
				{ ...coverages.get('Sygdom, tilskadekost og hjemtransport'), isActive: true },
				{ ...coverages.get('Kompensation for ødelagt rejse'), isActive: true },
				{ ...coverages.get('Forsinket bagage'), isActive: true },
				{ ...coverages.get('Forsinkelse'), isActive: true },
				{ ...coverages.get('Bilferie'), isActive: true },
				{ ...coverages.get('Evakuering'), isActive: true },
				{ ...coverages.get('Afbestilling'), isActive: true },
				{ ...coverages.get('Skitillæg'), isActive: true },
				{ ...coverages.get('Bagage, Ansvar og Retshjælp'), isActive: true },
			],
		},
	],
]);
