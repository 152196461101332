import { Checkbox, CheckboxProps } from '@almbrand/checkbox';
export interface CheckboxComponentProps extends CheckboxProps {
	register: any;
	contentType: string;
	stepContentId: string;
	submitStep?: () => void;
}
export const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
	register,
	submitStep,
	contentType,
	stepContentId,
	...props
}) => {
	const { onChange, name } = register(stepContentId.toString());
	const handleChange = (e) => {
		onChange({
			target: {
				name,
				value: {
					id: name,
					value: e.target.checked,
					type: contentType,
				},
			},
		});
		submitStep && submitStep();
	};
	return (
		<Checkbox
			{...props}
			onChange={handleChange}
		/>
	);
};
