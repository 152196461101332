import { Container } from '@almbrand/container';
import { FormWrapper } from '@almbrand/formwrapper';
import { Headline } from '@almbrand/headline';
import { Modal } from '@almbrand/modal';
import classNames from 'classnames';
import { ButtonNavigationComponent } from 'components/1-atoms/ButtonNavigationComponent';
import { TooltipComponent } from 'components/2-molecules/Tooltip';
import { PackageComponent } from 'components/4-habitats/PackageComponent';
import { StepComponent } from 'components/4-habitats/StepComponent';
import { Component } from 'components/Component';
import { IsPackageComponentContext } from 'context/IsPackageComponentContext';
import { useFormSubmit } from 'hooks';
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { isFieldHidden } from '../../../services/DependencyService';
import { isPackageStep } from '../../../services/PackageService';
import { replacePlaceholders } from '../../../services/PlaceholderService';
import styles from './PageTemplate.module.scss';

export interface PageTemplateProps {
	pageData?: StepBlock;
}
export const PageTemplate: React.FC<PageTemplateProps> = ({ pageData }) => {
	const [isHorizontal, setIsHorizontal] = useState(false);
	const { title, fields, contentId: stepContentId, tooltip } = pageData || {};

	const methods = useForm({ mode: 'onTouched' });
	const {
		handleSubmit,
		formState: { errors, isValid },
		register,
	} = methods;

	const { onSubmit } = useFormSubmit();

	const { setIsVisible, isVisible } = useContext(IsPackageComponentContext);

	const modal = useAppSelector((state) => state.modal);

	const isPackages = isPackageStep(pageData);

	useEffect(() => {
		setTimeout(() => {
			setIsVisible(isPackages);
		}, 100);
	}, []);

	const mapComponents = () => {
		return fields.items.map((item, index) => {
			if (isFieldHidden(item)) {
				// hide hidden fields
				return undefined;
			}

			// Map uniqueIdentifier?.uid to content.name
			const content = {
				...item.content,
				name: item.content.uniqueIdentifier?.uid || 'NO_UNIQUE_ID_FOR_CONTENT_ITEM',
			};

			const { contentId, name, contentType, title } = content;
			const updatedComponentProps = {
				index,
				// stepContentId: stepContentId,
				stepContentId: content.name,
				register,
				hasError: !!errors?.[content.name],
				isValid,
				// submitStep: () => handleSubmit(onSubmit)(),
				...content,
			};

			replacePlaceholders(updatedComponentProps);

			return (
				<Component
					key={contentId}
					{...updatedComponentProps}
				/>
			);
		});
	};

	return (
		<div className={classNames(styles.PageTemplate, isVisible && styles['PageTemplate--full-height'])}>
			<FormProvider {...methods}>
				<FormWrapper
					onSubmit={handleSubmit(onSubmit)}
					className={styles.PageTemplate__Form}
				>
					{!isPackages && (
						<StepComponent
							fields={fields}
							isHorizontal={(value) => setIsHorizontal(value)}
						>
							<Container mobileWidth='342'>
								<div className={styles.PageTemplate__Form__Headline}>
									<Headline
										title={title}
										size='default'
										variant='h3'
									/>
								</div>
							</Container>
							{tooltip && <TooltipComponent {...tooltip} />}
							<div
								className={classNames(
									styles.PageTemplate__Form__Content,
									isHorizontal && styles['PageTemplate__Form__Content--horizontal']
								)}
							>
								{mapComponents()}
							</div>
							<div className={styles.PageTemplate__navigation}>
								<ButtonNavigationComponent
									isSubmit={false}
									isValid={true}
									pageId={pageData.contentId}
									label='' // get this from CMS
								/>
								<ButtonNavigationComponent
									isSubmit={true}
									submit={handleSubmit(onSubmit)}
									isValid={isValid}
									pageId={pageData.contentId}
									label='Fortsæt' // get this from CMS
								/>
							</div>
						</StepComponent>
					)}
					{isPackages && <PackageComponent pageData={pageData} />}
					{modal.displayModal && <Modal {...modal.modalProps} />}
				</FormWrapper>
			</FormProvider>
		</div>
	);
};
