import { Container } from '@almbrand/container';
import { HeaderComponent, MultiplePageTemplate } from 'components';
import { IsPackageComponentContext } from 'context/IsPackageComponentContext';
import { PageContext } from 'context/PageContext';
import { memo, useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setPlaceholderDefinitions } from 'store/slices/placeholderSlice';
import { submitHiddenFieldsToForm } from '../../../services/HiddenFieldService';
import styles from './ProductCalculatorPage.module.scss';

export const ProductCalculatorPage: React.FC<PageModel> = memo(() => {
	const pageContext = useContext(PageContext);
	const { headerContent, pageModel, metaArea } = pageContext ?? {};

	const customer = useAppSelector((state) => state.customer);

	const { isVisible } = useContext(IsPackageComponentContext);

	const [showLoginButton, setShowLoginButton] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	if (pageModel?.hiddenFields) {
		submitHiddenFieldsToForm(pageModel.hiddenFields);
	}

	useEffect(() => {
		if (customer.data.isDataFetched && !customer.data.isLoggedIn) {
			setShowLoginButton(true);
		}
	}, [customer]);

	useEffect(() => {
		dispatch(setPlaceholderDefinitions(metaArea));
	}, []);

	return (
		<>
			<div
				className={
					isVisible
						? styles.ProductCalculatorPage__HeaderComponent__packages
						: styles.ProductCalculatorPage__HeaderComponent
				}
			>
				<Container
					desktopWidth='1600'
					tabletWidth='1280'
					mobileWidth='342'
				>
					<HeaderComponent
						{...headerContent}
						showLoginButton={showLoginButton}
						loginLabelText={'Login'}
					/>
				</Container>
			</div>
			<Container
				desktopWidth='1600'
				tabletWidth='fullWidth'
				mobileWidth={isVisible ? 'fullWidth' : '360'}
				noPadding
			>
				{/* <Container
					desktopWidth='2500'
					tabletWidth='1600'
					mobileWidth='fullWidth'
				>
					<Grid
						container
						nogutter
					>
						<GridCell desktopWidth='9col'> */}
				<MultiplePageTemplate pageModel={pageModel} />
				{/* </GridCell>
					</Grid>
				</Container> */}
			</Container>
			{/* {!isVisible && (
				<Container mobileWidth='fullWidth'>
					<div
						style={{
							height: 128,
							borderTopLeftRadius: 32,
							borderTopRightRadius: 32,
							background: '#FFF',
							display: 'flex',
							justifyContent: 'center',
							padding: '32px 40px 40px 40px',
							position: 'relative',
						}}
					>
						<Headline
							title='NAVIGATION'
							size='default'
							variant='h2'
						/>
					</div>
				</Container>
			)} */}
		</>
	);
});

ProductCalculatorPage.displayName = 'ProductCalculatorPage';
