import { SearchFieldOption } from 'components/2-molecules/SearchFieldComponent/SearchFieldComponent';
import { apiInstance } from '../services/Api';
import { SearchComponent, SearchConfiguration } from './SearchConfiguration';

// icon: IconIsTrue<T> | IconIsFalse;
// options: Option[];
// isDisabled?: boolean;
// isSearchable?: boolean;
// onChange?: (value: SingleValue<Option>) => void;
// hasError?: boolean;
// required?: boolean;
// placeHolder?: string;
// errorMessage?: string;
// id?: string;

// 	insurance_companies: [
// 		{
// 			company_id: '102',
// 			company_name: 'Lloyds v/First',
// 			is_auto_insurer: true,
// 			is_accident_insurer: true,
// 			vir_number: 'VIR245224',
// 		},
// 	],
// };

export class InsuranceCompanySearch implements SearchComponent {
	searchConfiguration: SearchConfiguration;
	apiSearch: boolean;
	searchIcon: string;

	constructor(searchConfiguration: SearchConfiguration) {
		this.searchConfiguration = searchConfiguration;
		this.apiSearch = false;
		this.searchIcon = 'magnefier';
	}

	init = async (): Promise<SearchFieldOption[]> => {
		const options: SearchFieldOption[] = [];
		try {
			const res: any = await apiInstance().get(this.searchConfiguration.url);
			res?.insurance_companies?.forEach((element) => {
				options.push({
					label: element.company_name,
					value: element.vir_number,
				});
			});

			// sort result alphabetically
			options.sort((a, b) => a.label.localeCompare(b.label));
		} catch (error) {
			console.error(error);
		}

		return options;
	};
}
