import { RichText } from '@almbrand/richtext';
import { forwardRef, useEffect, useId, useState } from 'react';
import { apiInstance } from '../../../services/Api';
import { ToggleLink } from '../../1-atoms/ToggleLink';
import styles from './CheckboxConsentComponent.module.scss';

// checkBox copied from abg-component-library v. 1.0.18

export interface CheckboxConsentComponentProps extends React.HTMLAttributes<HTMLInputElement> {
	name: string;
	value?: string | number;
	defaultChecked?: boolean;
	checked?: boolean;
	id?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	preText?: string;
	linkTextClosed?: string;
	linkTextOpen?: string;
	consentText?: string;
	placeholders?: Array<{ key: string; value: string }>;
	endpoint?: string;
}

export const CheckboxConsentComponent = forwardRef<HTMLInputElement, CheckboxConsentComponentProps>(
	(
		{
			id,
			name,
			value,
			onChange,
			defaultChecked,
			checked,
			disabled,
			preText,
			linkTextClosed,
			linkTextOpen,
			consentText,
			placeholders,
			endpoint,
		},
		ref
	) => {
		const generatedId = useId();

		const [isChecked, setIsChecked] = useState<boolean>(false);
		const [consentPreText, setConsentPreText] = useState<string>(undefined);
		const [consentPostText, setConsentPostText] = useState<string>(undefined);

		useEffect(() => {
			if (checked !== undefined) {
				setIsChecked(checked);
			}
		}, [checked]);

		useEffect(() => {
			const axios = apiInstance(2000);
			const getConsent = async () => {
				let res;
				try {
					res = await axios.get(endpoint);
				} catch (e) {
					// move on
				}
				const consentApi = res?.find(
					(consent) =>
						consent.developerName === 'onlinetermination_private' && consent.consentText?.length > 0
				);
				replaceTokens(consentApi?.consentText || consentText);
			};
			getConsent();
		}, []);

		const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			setIsChecked(event.target.checked);
			if (onChange) {
				onChange(event);
			}
		};

		const replaceTokens = (postText: string) => {
			placeholders.forEach((entry) => {
				// const re = new RegExp(entry.key, 'gi');
				// preText = preText.replace(re, entry.value);
				// postText = postText.replace(re, entry.value);
				preText = preText.replaceAll(entry.key, entry.value);
				postText = postText.replaceAll(entry.key, entry.value);
			});
			setConsentPreText(preText);
			setConsentPostText(postText);
		};

		return (
			<label className={styles.Checkbox}>
				<div className={styles.ContentWrapper}>
					<RichText
						description={consentPreText}
						className='NA'
					/>
					{
						<ToggleLink
							linkText={linkTextOpen}
							closeLinkText={linkTextClosed}
							toggleContentText={consentPostText}
						/>
					}
				</div>

				<input
					disabled={disabled}
					value={value}
					defaultChecked={defaultChecked}
					onChange={handleOnChange}
					id={id ?? generatedId}
					name={name}
					className={styles.Checkbox_input}
					type='checkbox'
					ref={ref}
					checked={isChecked}
				/>
				<span className={styles.Checkbox_checkmark} />
			</label>
		);
	}
);

CheckboxConsentComponent.displayName = 'Checkbox';
