import { SearchFieldOption } from 'components/2-molecules/SearchFieldComponent/SearchFieldComponent';
import { apiInstance } from '../services/Api';
import { FrontendDatasourceEvent, SearchComponent, SearchConfiguration } from './SearchConfiguration';

// [
// 	{
//     "tekst": "1200 København K",
//     "postnummer": {
//         "nr": "1200",
//         "navn": "København K",
//         "stormodtager": false,
//         "visueltcenter_x": 12.5798222,
//         "visueltcenter_y": 55.67820341,
//         "href": "https://api.dataforsyningen.dk/postnumre/1200"
//     }
// }
// ]

// [
// {
//     "data": {
//         "id": "0a3f507b-cc63-32b8-e044-0003ba298018",
//         "status": 1,
//         "darstatus": 3,
//         "vejkode": "0001",
//         "vejnavn": "Adolphsvej",
//         "adresseringsvejnavn": "Adolphsvej",
//         "husnr": "19",
//         "supplerendebynavn": null,
//         "postnr": "2820",
//         "postnrnavn": "Gentofte",
//         "stormodtagerpostnr": null,
//         "stormodtagerpostnrnavn": null,
//         "kommunekode": "0157",
//         "x": 12.5535414,
//         "y": 55.74751978,
//         "href": "https://api.dataforsyningen.dk/adgangsadresser/0a3f507b-cc63-32b8-e044-0003ba298018"
//     },
//     "stormodtagerpostnr": false,
//     "type": "adgangsadresse",
//     "tekst": "Adolphsvej 19, , 2820 Gentofte",
//     "forslagstekst": "Adolphsvej 19, 2820 Gentofte",
//     "caretpos": 15
// }
// ]
export class ZipcodeAddressSearch implements SearchComponent {
	searchConfiguration: SearchConfiguration;
	apiSearch: boolean;
	isZipcodeSearch: boolean;
	minInputLength: number;
	hasHadResult: boolean;
	searchIcon: string;
	lastValue: string;
	latency: number;

	constructor(searchConfiguration: SearchConfiguration) {
		this.searchConfiguration = searchConfiguration;
		this.apiSearch = true;
		this.isZipcodeSearch = searchConfiguration.frontendDatasourceEvent === FrontendDatasourceEvent.ZIPCODE;
		this.minInputLength = this.isZipcodeSearch ? 1 : 2;
		this.searchIcon = 'map-pin';
		this.latency = 100;
	}

	init = async (): Promise<SearchFieldOption[]> => {
		return undefined;
	};

	handleInputChange = async (value: string): Promise<SearchFieldOption[]> => {
		if (value?.trim().length >= this.minInputLength) {
			this.lastValue = value;
			await new Promise((res) => setTimeout(res, this.latency));
			if (this.lastValue !== value) {
				// a new typing overrides this
				return;
			}

			const options: SearchFieldOption[] = [];
			try {
				const res: any = await apiInstance().get(this.searchConfiguration.url + encodeURI(value));

				res?.forEach((element) => {
					this.hasHadResult = true;
					if (this.isZipcodeSearch) {
						options.push({
							label: element.tekst,
							value: element.postnummer?.nr,
						});
					} else {
						options.push({
							label: element.forslagstekst,
							value: element.forslagstekst,
						});
					}
				});
			} catch (error) {
				console.error();
			}
			return options;
		}
		return this.hasHadResult ? [] : undefined;
	};
}
