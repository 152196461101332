import { ScrollableRadioList, ScrollableRadioListProps } from '@almbrand/scrollableradiolist';
import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export interface ScrollableRadioListComponentProps extends ScrollableRadioListProps<ThemeUnion> {
	register: any;
	contentType: string;
	submitStep?: () => void;
}

export const ScrollableRadioListComponent: React.FC<ScrollableRadioListComponentProps> = ({
	register,
	submitStep,
	name,
	contentType,
	...props
}) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];
	const { onChange, name: scrollableRadioListName } = register(name, { required: true });

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;

		onChange({
			target: {
				name: scrollableRadioListName,
				value: {
					id: name,
					value: value,
					type: contentType,
				},
			},
		});
		submitStep && submitStep();
	};
	return (
		<ScrollableRadioList
			{...props}
			themeName={themeName}
			onChange={handleOnChange}
		/>
	);
};
