import {
	ButtonComponent,
	CheckboxComponent,
	CheckboxConsentComponent,
	DatePickerComponent,
	IframeComponent,
	InputTextFieldComponent,
	RadioCardComponent,
	ScrollableRadioListComponent,
	SearchFieldComponent,
	SelectFieldComponent,
} from 'components';

import { TeaserCard } from '@almbrand/teasercard';

export const Component: React.FC<any> = (props) => {
	const components = {
		CheckboxFieldBlock: CheckboxComponent,
		DatePickerFieldBlock: DatePickerComponent,
		InlineFrameBlock: IframeComponent,
		InputTextfieldBlock: InputTextFieldComponent,
		RadioCardFieldBlock: RadioCardComponent,
		SearchFieldBlock: SearchFieldComponent,
		SelectListFieldBlock: SelectFieldComponent,
		ScrollableRadioListFieldBlock: ScrollableRadioListComponent,
		ButtonBlock: ButtonComponent,
		UserConsentBlock: CheckboxConsentComponent,
		TeaserCardBlock: TeaserCard,
	};

	const DynamicComponent = components[props.contentType as keyof typeof components];

	if (typeof DynamicComponent === 'undefined') {
		// TODO: Should it break in build?s
		console.error(`Component [${props.contentType}] is missing a React Component. Check /components/Component.tsx`);
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100px',
					width: '100%',
					border: '10px red solid',
					fontSize: 'larger',
					fontWeight: 'bold',
					boxSizing: 'border-box',
				}}
			>{`Component [${props.contentType}] is missing a React Component. Check /components/Component.tsx`}</div>
		);
	}

	return <DynamicComponent {...props} />;
};
