import { SystemIcons } from '@almbrand/systemicons';
import { IconsProps, ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import classNames from 'classnames';
import { useId, useState } from 'react';
import Select, { SingleValue, StylesConfig } from 'react-select';
import styles from './SelectField.module.scss';

type IconIsTrue<T extends ThemeUnion> = {
	iconProp: IconsProps<T>;
};

type IconIsFalse = {
	isIcon: false;
};

export type Option = {
	value: string;
	label: string;
};

export interface SelectFieldProps<T extends ThemeUnion> {
	icon: IconIsTrue<T> | IconIsFalse;
	options: Option[];
	isDisabled?: boolean;
	isSearchable?: boolean;
	noOptionsMessage?: string;
	onChange?: (value: SingleValue<Option>) => void;
	onInputChange?: (value: string) => void;
	menuIsOpen?: boolean;
	filterOptions?: 'ignore';
	hasError?: boolean;
	required?: boolean;
	placeHolder?: string;
	errorMessage?: string;
	id?: string;
}

export const SelectField: React.FC<SelectFieldProps<ThemeUnion>> = ({
	icon,
	options,
	isSearchable,
	isDisabled,
	noOptionsMessage = 'Ingen resultater',
	onChange,
	onInputChange,
	menuIsOpen,
	filterOptions,
	hasError = false,
	required,
	placeHolder,
	errorMessage,
	id,
}) => {
	const [optionsIsSelected, setOptionsIsSelected] = useState(false);
	const [selectIsTouched, setSelectIsTouched] = useState(false);
	const generatedId = useId();

	const styles1: StylesConfig = {
		container: (css) => ({ ...css, maxWidth: '33.75rem', minHeight: '4rem', minWidth: '20rem' }),
		dropdownIndicator: (css) => ({
			...css,
			minWidth: '36px !important',
			minHeight: '36px !important',
		}),
	};

	return (
		<div onBlur={() => setSelectIsTouched(true)}>
			<div className={classNames(styles.FixedIconContainer)}>
				<span className={classNames(styles.FixedIcon)}>
					{'iconProp' in icon && icon.iconProp.icon && (
						<SystemIcons
							height={35}
							width={35}
							themeName={icon.iconProp.themeName}
							icon={icon.iconProp.icon}
						/>
					)}
				</span>
			</div>
			<Select
				id={id ?? generatedId}
				isSearchable={isSearchable}
				isDisabled={isDisabled}
				options={options}
				onChange={(value: Option) => {
					setOptionsIsSelected(true);
					onChange && onChange(value);
				}}
				onInputChange={onInputChange}
				classNames={{
					control: ({ isFocused }) =>
						classNames(
							styles.SelectContainer,
							isFocused && styles.SelectContainer__isFocused,
							optionsIsSelected && styles.SelectContainer__isSelected,
							hasError && !isFocused && styles.SelectContainer__error,
							selectIsTouched && !optionsIsSelected && styles.SelectContainer__error
						),

					menuList: () => classNames(styles.SelectContainer__menu),

					option: ({ isFocused, isSelected }) =>
						classNames(
							styles.SelectContainer__option,
							isFocused && styles.SelectContainer__option_focused,
							isSelected && styles.SelectContainer__option_selected
						),
					placeholder: () => styles.SelectContainer__placeholder,

					input: () => classNames(styles.SelectContainer__input),

					singleValue: () => classNames(styles.SelectContainer__value),
				}}
				unstyled
				placeholder={placeHolder}
				required={required}
				noOptionsMessage={() => noOptionsMessage}
				menuIsOpen={menuIsOpen}
				filterOption={filterOptions === 'ignore' ? () => true : undefined}
				styles={styles1}
			/>
			{(hasError || selectIsTouched) && !optionsIsSelected && (
				<p
					role='alert'
					className={styles.SelectField__errorMessage}
				>
					{errorMessage}
				</p>
			)}
		</div>
	);
};
