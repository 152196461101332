export const mapPackages = (pageData: StepBlock): Map<string, PackageConfig> => {
	const packages: Map<string, PackageConfig> = new Map(); //mock3PackagesMap;
	pageData.fields?.items?.forEach((field: PackageDetailsBlock) => {
		if (field.content.contentType === 'PackageDetailsBlock') {
			const coverGroups: CoverGroup[] = [];
			field.content.includedCovers?.items?.forEach((cover: CoverageGroupDetailsBlock) => {
				coverGroups.push(mapCoverage(cover, true));
			});
			field.content.notIncludedCovers?.items?.forEach((cover: CoverageGroupDetailsBlock) => {
				coverGroups.push(mapCoverage(cover, false));
			});
			packages.set(field.content.packageName, {
				coverages: coverGroups,
				discountText: field.content.discountText,
				isPrimaryPackage: field.content.isPrimary,
			});
		}
	});
	return packages;
};

const mapCoverage = (cover: CoverageGroupDetailsBlock, isActive: boolean): CoverGroup => {
	const element = cover.content;
	const title = element.coverageTooltip.linkText || element.coverageTooltip.title;
	return {
		coverName: title,
		cover_group_id: element.coverageGroupId.uid,
		coverDescription: {
			content: element.coverageTooltip.content,
			subtitle: element.coverageTooltip.subtitle,
			title,
		},
		isActive,
	};
};

export const isPackageStep = (pageData: StepBlock): boolean => {
	const hasPackageDetailsBlock = pageData?.fields?.items?.some(
		(item) => item.content?.contentType === 'PackageDetailsBlock'
	);
	return hasPackageDetailsBlock;
};
