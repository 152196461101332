import { List } from '@almbrand/list';
import { LoadingSpinner } from '@almbrand/loadingspinner';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { useModal } from 'hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { formatNumber } from '../../../../utilities/format';
import styles from './PackageTile.module.scss';
import { SelectPackageButton } from './SelectPackageButton';

export interface PackageTileProps {
	header: {
		title: string;
		discountText?: string;
	};
	priceBase: number;
	priceDiscount: number;
	coverages: CoverGroup[];
	isLoading?: boolean;
	pageId: number;
}

export const PackageTile: React.FC<PackageTileProps> = ({
	header,
	isLoading = false,
	coverages,
	priceBase,
	priceDiscount,
	pageId,
}) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];

	const [selected, setSelected] = useState<boolean>(false);

	const form = useAppSelector((state) => state.calculatorForm);
	const { displayModal } = useModal();

	const mapItems = () =>
		coverages.map((coverage, index) => (
			<button
				onClick={() => modalCoverage(coverage)}
				key={coverage.coverName}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						modalCoverage(coverage);
					}
				}}
				className={styles.PackageTile_coverages_button}
			>
				<List
					items={[
						{
							iconProp: {
								themeName,
								icon: coverage.isActive ? 'check' : 'line',
							},
							text: coverage.coverName,
							id: index,
							...(!coverage.isActive ? { className: styles.notActive } : null),
						},
					]}
				/>
			</button>
		));

	useEffect(() => {
		const id = form.SELECTED_PACKAGE?.data?.id;
		setSelected(id === header.title);
	}, [form.SELECTED_PACKAGE]);

	const modalCoverage = (coverage: CoverGroup) => {
		const { title, subtitle, content } = coverage.coverDescription;
		displayModal(title, subtitle, content);
	};

	return (
		<div className={styles.PackageTile}>
			<div className={styles.PackageTile_header}>
				<h3 className={styles.PackageTile_header_title}>{header.title}</h3>
				<h1 className={styles.PackageTile_header_price}>
					{isLoading ? (
						<LoadingSpinner
							style='small'
							type='dotted'
							color='primary-5'
						/>
					) : (
						formatNumber(priceDiscount)
					)}
					{' kr./md'}
				</h1>
				{header.discountText && (
					<div className={styles.PackageTile_header_discount}>
						{header.discountText}
						{isLoading ? (
							<LoadingSpinner
								style='flat'
								type='dotted'
								color='secondary-3'
							/>
						) : (
							<>
								<span>&nbsp;</span>
								<s>{formatNumber(priceBase)} kr./md.</s>
							</>
						)}
					</div>
				)}
			</div>

			<div className={styles.PackageTile_coverages}>{mapItems()}</div>

			<SelectPackageButton
				header={header}
				setSelected={setSelected}
				isLoading={isLoading}
				isSelected={selected}
				priceDiscount={priceDiscount}
				pageId={pageId}
			/>
		</div>
	);
};
