import { Carousel, CarouselItem, HeaderItemStyle } from '@almbrand/carousel';
import { Headline } from '@almbrand/headline';
import { ButtonComponent } from 'components/1-atoms';
import { ButtonNavigationComponent } from 'components/1-atoms/ButtonNavigationComponent';
import { PageTemplateProps } from 'components/5-templates/PageTemplate/PageTemplate';
import { PageContext } from 'context/PageContext';
import { useModal } from 'hooks';
import { useContext, useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { calculatePackage } from '../../../api/CalculateApi';
import { mockProductInformation } from '../../../constantsValues/MockPackages';
import { mapPackages } from '../../../services/PackageService';
import styles from './PackageComponent.module.scss';
import { PackageTile } from './tiles';

interface Prices {
	priceBase?: number;
	priceDiscount?: number;
}

export const PackageComponent: React.FC<PageTemplateProps> = ({ pageData }) => {
	const packageRef = useRef<HTMLDivElement>();
	const [inView, setInView] = useState<boolean>(false);

	const customer = useAppSelector((state) => state.customer);

	const packages: Map<string, PackageConfig> = mapPackages(pageData);

	const pageContext = useContext(PageContext);

	const [priceFormFields, setPriceFormFields] = useState<Array<string>>();

	const form = useAppSelector((state) => state.calculatorForm);

	const [calcParams, setCalcParams] = useState<Map<string, string>>();

	const [doCalculate, setDoCalculate] = useState<boolean>(false);
	const [doCalculateSecondary, setDoCalculateSecondary] = useState<boolean>(false);

	const [callOrder, setCallOrder] = useState<Array<number>>();
	const [secondaryCalcCallOrder, setSecondaryCalcCallOrder] = useState<Array<number>>();

	const [packageNames, setPackageNames] = useState<Array<string>>();
	const [packagePrices, setPackagePrices] = useState<Map<string, Prices>>();
	const [activeItem, setActiveItem] = useState<number>();

	const { displayErrorModal, displayModal } = useModal();

	const addPackagePrice = (map: Map<string, Prices>) => {
		const mergedMap = new Map([...Array.from(packagePrices.entries()), ...Array.from(map.entries())]);
		setPackagePrices(mergedMap);
	};

	const getPrice = async (calcParams: Map<string, string>, packageName: string) => {
		if (!doCalculate) {
			return;
		}
		try {
			const res: any = await calculatePackage(
				calcParams,
				pageContext.metaArea,
				packages.get(packageName).coverages,
				customer
			);
			return res.total_price / 12;
		} catch (e) {
			console.error(e);
			let title: string;
			let subtitle: string;
			let content: string;

			const detail: string = e?.response?.data?.detail;
			if (detail?.includes('SPD - Message : {')) {
				const spdMsg = detail.substring(detail.indexOf('SPD - Message : {') + 16, detail.length - 1);
				const spdError = JSON.parse(spdMsg);
				const { errors } = spdError;
				if (errors?.length > 0) {
					title = errors[0].errorCode;
					subtitle = 'Error message';
					content = errors[0].errorMessage;
				}
			} else if (e?.code === 'ECONNABORTED') {
				title = 'Request timeout';
				subtitle = 'Error message';
				content = e.message;
			}
			displayErrorModal(title, subtitle, content);
			if (location.href.startsWith('https://localhost') || location.href.startsWith('https://sit-')) {
				// get price in test
				return parseInt(500 * Math.random() + '');
			}
		}
	};

	const getCallOrder = (inx: number, callOrderCopy: number[]): number[] => {
		const order = [...callOrderCopy];
		order.sort((a, b) => a - b);
		order.unshift(order.splice(inx, 1)[0]);
		return order;
	};

	const setupCalculation = () => {
		if (!priceFormFields) {
			return;
		}

		const map: Map<string, string> = new Map();
		priceFormFields.forEach((key) => {
			if (form[key].data) {
				map.set(form[key].data.id, form[key].data.value);
			} else {
				console.warn('PackageComponent could not key find in store', key);
			}
		});

		// anonymous call
		// kvhx search is still not working, set here zipcode information hardcoded
		if (!customer.data.isLoggedIn) {
			console.warn('No customer logged in -> adding mock zipcode and kvhx to anonymous calculation');

			map.set('PT_PT_PTO_POST_AREA', '4320');
			map.set('PT_PT_PTO_COUNTRY_CODE', 'DK');
			map.set('PT_PT_PTO_GEO_KVHX', '2531043001');
		}

		setCalcParams(map);

		const initPackagePricesMap = new Map<string, Prices>();
		const initPackageNames: string[] = [];
		const order: number[] = [];
		let count = 0;
		let primary = 0;
		packages.forEach((packageConfig, key) => {
			initPackagePricesMap.set(key, { priceBase: undefined, priceDiscount: undefined });
			initPackageNames.push(key);
			order.push(count);
			if (packageConfig.isPrimaryPackage) {
				primary = count;
			}
			count++;
		});

		setPackagePrices(initPackagePricesMap);
		setPackageNames(initPackageNames);

		if (!callOrder) {
			setCallOrder(getCallOrder(primary, order));
		}

		setDoCalculate(true);
	};

	const mapComponents = (): CarouselItem[] => {
		const headerItemStyle: HeaderItemStyle = {
			color: 'secondaryGrey',
			colorActive: 'primaryDark',
			background: undefined,
			backgroundActive: 'primaryWhite',
		};
		return packageNames.map((packageName) => {
			const prices = packagePrices.get(packageName);
			const packageConfig = packages.get(packageName);
			const carouselItem: CarouselItem = {
				header: packageName,
				headerItemStyle,
				jsxElementBackgroundColor: 'secondaryLightGrey',

				jsxElement: (
					<PackageTile
						header={{
							title: packageName,
							discountText: packageConfig.discountText,
						}}
						priceBase={prices.priceBase}
						priceDiscount={prices.priceDiscount}
						coverages={packageConfig.coverages}
						isLoading={prices?.priceBase === undefined}
						pageId={pageData.contentId}
					/>
				),
			};
			return carouselItem;
		});
	};

	useEffect(() => {
		const priceFormFieldsInit = [];

		Object.keys(form).forEach((key) => {
			priceFormFieldsInit.push(key);
		});
		setPriceFormFields(priceFormFieldsInit);
	}, []);

	useEffect(() => {
		setupCalculation();
	}, [priceFormFields]);

	useEffect(() => {
		if (!packageRef.current) {
			return;
		}
		const observer = new IntersectionObserver(
			([entry]) => {
				setInView(entry.isIntersecting);
			},
			{
				root: null,
				rootMargin: '0px',
				threshold: 0.1,
			}
		);
		observer.observe(packageRef.current);

		return () => {
			observer.disconnect();
		};
	}, [packageRef.current]);

	useEffect(() => {
		if (inView && priceFormFields) {
			setupCalculation();
		}
	}, [inView]);

	useEffect(() => {
		if (!doCalculate) {
			return;
		}
		const call = async () => {
			// make certain that calcOrder is not affected by user changes during calculations
			const secondaryOrder = [...callOrder];
			const calcInx = secondaryOrder[0];
			// remove calcInx since this is the first call
			secondaryOrder.splice(0, 1);
			setSecondaryCalcCallOrder(secondaryOrder);

			const packageName = packageNames[calcInx];

			const price = await getPrice(calcParams, packageName);

			const resMap = new Map<string, Prices>();
			resMap.set(packageName, {
				priceBase: price,
				priceDiscount: price * 0.9,
			});

			addPackagePrice(resMap);

			setDoCalculateSecondary(true);
		};

		call();
	}, [doCalculate]);

	useEffect(() => {
		if (!doCalculate || !doCalculateSecondary) {
			return;
		}
		const call = async () => {
			const resMap = new Map<string, Prices>();

			const promises = [];
			secondaryCalcCallOrder.forEach((calcInx) => {
				promises.push(
					getPrice(calcParams, packageNames[calcInx]).then((price) => {
						resMap.set(packageNames[calcInx], {
							priceBase: price,
							priceDiscount: price * 0.9,
						});
					})
				);
			});
			await Promise.all(promises);

			addPackagePrice(resMap);
			setDoCalculate(false);
			setDoCalculateSecondary(false);
		};

		call();
	}, [doCalculateSecondary]);

	useEffect(() => {
		if (activeItem >= 0 && callOrder?.length > 0) {
			setCallOrder(getCallOrder(activeItem, callOrder));
		}
	}, [activeItem]);

	const iPidModal = () => {
		const { title, subtitle, content } = mockProductInformation;
		displayModal(title, subtitle, content);
	};
	return (
		callOrder && (
			<div
				className={styles.packages}
				ref={packageRef}
			>
				<Carousel
					topSection={
						<>
							<ButtonNavigationComponent
								isSubmit={false}
								isValid={true}
								pageId={pageData.contentId}
								label='Tilbage' // get this from CMS
								isPackage={true}
							/>
							<div className={styles.packages__topSection}>
								<Headline
									title='Vælg din pakke'
									variant='h2'
									fontColor='white'
									size='default'
								/>
							</div>
						</>
					}
					headerItemsBackgroundColor={'#081047'}
					items={mapComponents()}
					dragThreshold={35}
					getActiveItem={setActiveItem}
					offset='left'
					springEffect={{ stiffness: 200, damping: 20 }}
					startingItem={callOrder[0]}
					bottomSection={
						<div className={styles.packages__bottomSection}>
							<ButtonComponent
								isValid={true}
								label='Produktinformation og betingelser'
								buttonType='link'
								type='button'
								onClick={iPidModal}
							>
								<></>
							</ButtonComponent>
						</div>
					}
				/>
			</div>
		)
	);
};
