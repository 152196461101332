import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { SearchFieldOption } from 'components/2-molecules/SearchFieldComponent/SearchFieldComponent';
import {
	Option,
	SelectField,
	SelectFieldProps,
} from 'components/2-molecules/SearchFieldComponent/SelectField/SelectField';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export interface SelectFieldComponentProps extends SelectFieldProps<ThemeUnion> {
	register: any;
	contentType: string;
	name: string;
	submitStep?: () => void;
}

export const SelectFieldComponent: React.FC<SelectFieldComponentProps> = ({
	register,
	submitStep,
	name,
	contentType,
	...props
}) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];
	const { onChange, name: SelectFieldName } = register(name, { required: true });

	// map from CMS options to selectField option type
	if (props.options) {
		const options: Option[] = [];
		props.options.forEach((opt) => {
			options.push({
				label: (opt as any).key,
				value: opt.value,
			});
		});
		props.options = options;
	}

	// map from CMS
	props.menuIsOpen = !props.menuIsOpen ? undefined : true;
	props.placeHolder = (props as any).placeholder;

	const handleOnChange = (option: SearchFieldOption) => {
		onChange({
			target: {
				name: SelectFieldName,
				value: {
					id: name,
					label: option.label,
					value: option.value,
					type: contentType,
				},
			},
		});
		submitStep && submitStep();
	};
	return (
		<SelectField
			{...props}
			icon={{ iconProp: { themeName, icon: 'arrow_down' } }}
			onChange={handleOnChange}
		/>
	);
};
