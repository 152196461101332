import { useFormSubmit } from 'hooks';

/**
 * Find hidden fields on pageModel and remove them form pageModel. After removal add fields to pageModel.hiddenFields.
 * Run through pageModel and remove any empty sections
 * @param pageModel
 * @returns
 */
export const handleHiddenFields = (pageModel: PageModel) => {
	if (!pageModel) {
		return;
	}

	// find, remove and store hiddenFields
	const hiddenFields = [];

	hiddenFields.push(...removeHiddenFields(pageModel.campaignArea));
	hiddenFields.push(...removeHiddenFields(pageModel.prePriceArea));
	hiddenFields.push(...removeHiddenFields(pageModel.priceArea));
	hiddenFields.push(...removeHiddenFields(pageModel.postPriceArea));

	if (hiddenFields.length > 0) {
		pageModel.hiddenFields = hiddenFields;
	}

	// remove empty sections if any
	removeEmptySections(pageModel.campaignArea);
	removeEmptySections(pageModel.prePriceArea);
	removeEmptySections(pageModel.priceArea);
	removeEmptySections(pageModel.postPriceArea);
};

const removeHiddenFields = (section: ContentArea): FieldsContent[] => {
	const hiddenFields = [];
	section?.items?.forEach((item, sInx) => {
		item.content.steps?.items?.forEach((question, qInx) => {
			question.content.fields?.items?.forEach((element, eInx) => {
				/// handle more fields?
				if (element.content.contentType === 'HiddenFieldBlock') {
					hiddenFields.push(element);
					section.items[sInx].content.steps.items.splice(qInx, 1);
				}
			});
		});
	});
	return hiddenFields;
};

const removeEmptySections = (section: ContentArea) => {
	section?.items?.forEach((element, index) => {
		if (element.content.steps.items?.length === 0) {
			//console.log('REMOVE EMPTY SECTION', element.content.title);
			section.items.splice(index, 1);
		}
	});
};

export const submitHiddenFieldsToForm = (hiddenFields: FieldsContent[]) => {
	if (!hiddenFields || hiddenFields.length <= 0) {
		return;
	}

	const { onSubmit } = useFormSubmit();

	const formData = {};
	hiddenFields.forEach((element) => {
		formData[element.content.uniqueIdentifier.uid] = {
			id: element.content.uniqueIdentifier.uid,
			value: (element.content as any).dataEventConfiguration.eventToLoad,
			type: 'HiddenFieldBlock',
		};
	});

	if (Object.keys(formData).length) {
		onSubmit(formData);
	}
};
