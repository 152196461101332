import { CustomerState } from 'store/slices/customerSlice';
import { apiInstanceCached } from '../services/Api';
import { formatShortIsoDate } from '../utilities/format';

/**
 *
 * wait for current package calc, then call other packages
 */
export const calculatePackage = async (
	params: Map<string, string>,
	metaArea: MetaArea,
	coverGroups: CoverGroup[],
	customer: CustomerState
) => {
	const url = metaArea.items[0].content.quoteApiEndpoint.url;
	const condensedCoverGroups: CoverGroupCalculation[] = [];

	coverGroups.forEach((coverage) => {
		// only use relevant values
		if (coverage.isActive && coverage.cover_group_id) {
			// don't add duplicate covers
			if (!condensedCoverGroups.some((cover) => cover.cover_group_id === coverage.cover_group_id)) {
				condensedCoverGroups.push({ cover_group_id: coverage.cover_group_id, fields: coverage.fields });
			}
		}
	});

	const res = await apiInstanceCached().post(
		url,
		spdCalculate(params, metaArea.items[0], condensedCoverGroups, customer)
		// { cache: { interpretHeader: false, methods: ['post'] } }
	);
	if (res.cached) {
		// do delay
		await new Promise((res) => setTimeout(res, 500));
	}
	return res.data;
};

const spdCalculate = (
	params: Map<string, string>,
	metaAreaContent: MetaAreaContent,
	coverGroups: CoverGroupCalculation[],
	customer: CustomerState
) => {
	// customer_number: 9691310223,
	// anonymous call ->
	// customer_number: null,
	// ag: 40,

	const party = {
		cpr_number: null,
		customer_number: customer.data.customer_id ? customer.data.customer_id : null,
		age: customer.data.customer_id ? undefined : parseInt(params.get('CUSTOMER_AGE')),
		legal_information: {
			registered_in_rki: false,
		},
	};

	const fields: QuoteField[] = [];
	params.forEach((value, key) => fields.push({ id: key, value }));

	return {
		party,
		quote: {
			quote_lines: [
				{
					policy_number: null,
					cover_start_date: formatShortIsoDate(), //'2025-12-01',
					payment_frequency: 'MONTHLY',
					general_claim_info: {
						previous_claims: false,
						terminated_earlier: true,
						number_of_customer_years: 1,
						claims_last_3_years: 0,
					},
					product_details: {
						sales_product_name: metaAreaContent.content.salesProduct, // PT
						sales_product_version: metaAreaContent.content.salesProductVersion, //null,
						document_name: metaAreaContent.content.documentName, //
					},
					fields,
					cover_groups: coverGroups,
				},
				// add other product to same calculation. This won't fly online, unless we bundle packages with 1+ products
			],
			tag: {
				business_branch: 'Midtermolen',
				partner_agreement: '47b40e84-f0ce-4a15-8193-1fdeb4240077',
				partner_subagreement: '006e94cc-6d59-4d90-822d-762deaa21c42',
				partner_branch: 'Midtermolen',
			},
		},
	};
};
