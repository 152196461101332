import { ReactNode, useEffect, useId, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetFormItem } from 'store/slices/calculatorFormSlice';
import { setCurrentRadioState } from 'store/slices/radioCardSlice';
import styles from './StepComponent.module.scss';

export interface StepComponentProps {
	fields?: Fields;
	children?: ReactNode;
	isHorizontal?: (value: boolean) => void;
}

const compositeTypes = ['DatePickerFieldBlock', 'RadioCardFieldBlock', 'InputTextfieldBlock'];
export const StepComponent: React.FC<StepComponentProps> = ({ fields, children, isHorizontal }) => {
	const form = useAppSelector((state) => state.calculatorForm);
	const [key, setKey] = useState<string>(useId());
	const [stepName, setStepName] = useState<string>(undefined);
	const [hasCompositeComponents, setHasCompositeComponents] = useState<boolean>(false);
	const [hasRadioField, setHasRadioField] = useState<boolean>(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		isHorizontal && isHorizontal(alignFieldsHorizontal);
		initCompositeTypes();
	}, []);

	const alignFieldsHorizontal = fields.items.some((item) => {
		return item.content.contentType === 'RadioCardFieldBlock' && (item.content as any).cardImage?.url !== undefined;
	});

	const handleCompositeTypes = (e) => {
		const el = e.target;
		if (el instanceof HTMLInputElement) {
			if (hasRadioField && el?.type === 'text') {
				// TODO clear other textField types except this
				if (form[stepName]) {
					// reset radioFields
					dispatch(setCurrentRadioState({ index: -1, name: stepName }));
					dispatch(
						resetFormItem({
							key: stepName,
						})
					);
				}
			} else if (el?.type === 'radio') {
				//reset all - including value in dateField
				// the form store is updated by the clicked radio field
				setKey(key + '1');
			}
		}
	};

	const initCompositeTypes = () => {
		let lastContentType: string;
		fields?.items?.forEach((item) => {
			if (compositeTypes.includes(item.content.contentType)) {
				if (!lastContentType) {
					lastContentType = item.content.contentType;
				} else if (item.content.contentType !== lastContentType) {
					setHasCompositeComponents(true);
					setStepName(item.content.uniqueIdentifier?.uid);
				}
				if (item.content.contentType === 'RadioCardFieldBlock') {
					setHasRadioField(true);
				}
			}
		});
	};

	return (
		<div
			key={key}
			onClick={hasCompositeComponents ? handleCompositeTypes : undefined}
			className={styles.StepComponent}
		>
			{children}
		</div>
	);
};
