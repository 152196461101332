import classNames from 'classnames';
import { ThemeNames } from 'constantsValues';
import { IsPackageComponentContext } from 'context/IsPackageComponentContext';
import { PageContext } from 'context/PageContext';
import { useNavigation } from 'hooks';
import { useContext } from 'react';
import { useAppSelector } from 'store/hooks';
import { ButtonComponent } from '../ButtonComponent';
import styles from './ButtonNavigationComponent.module.scss';

export interface ButtonNavigationComponent {
	label: string;
	isValid: boolean;
	isSubmit: boolean;
	pageId: number;
	submit?: () => Promise<void>;
	isPackage?: boolean;
}
export const ButtonNavigationComponent: React.FC<ButtonNavigationComponent> = ({ ...props }) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];

	const { navigate } = useNavigation();

	const { orderedPageList } = useAppSelector((state) => state.pages);
	const { setIsVisible } = useContext(IsPackageComponentContext);

	const currentInx = orderedPageList.indexOf(props.pageId);

	const PACKAGE_PAGE_ID_HACK = 51515;

	const submit = () => {
		if (props.isValid) {
			props.submit().then(() => {
				navigate(props.pageId, true);
			});
		}

		hackForwardPackagePageUi();
	};

	const navigateBack = () => {
		if (currentInx > 0) {
			hackBackPackagePageUi();
			navigate(props.pageId, false);
		}
	};

	const hackForwardPackagePageUi = () => {
		// hack to handle package styles
		if (orderedPageList[currentInx + 1] === PACKAGE_PAGE_ID_HACK) {
			setTimeout(() => {
				setIsVisible(true);
			}, 100);
		}
	};
	const hackBackPackagePageUi = () => {
		// hack to handle packagePage styles
		setTimeout(() => {
			if (props.isPackage) {
				setIsVisible(false);
			} else if (orderedPageList[currentInx - 1] === PACKAGE_PAGE_ID_HACK) {
				setIsVisible(true);
			}
		}, 100);
	};
	const btnType = () => {
		if (!props.isSubmit && props.isPackage) {
			return 'link';
		}
		return props.isSubmit ? 'primary' : 'circle';
	};

	return props.isSubmit || props.pageId !== orderedPageList[0] ? (
		<div
			className={classNames(
				styles.ButtonNavigation,
				props.isPackage && styles.package,
				props.isSubmit && styles.ButtonNavigation__submit
			)}
		>
			<ButtonComponent
				type={'button'}
				isValid={props.isValid}
				label={props.label}
				buttonType={btnType()}
				onClick={!props.isSubmit ? navigateBack : submit}
				iconProps={
					!props.isSubmit && {
						themeName,
						icon: props.isPackage ? 'arrow_with_tale' : 'arrow_left',
						height: props.isPackage ? '16' : '40',
						width: props.isPackage ? '16' : '40',
					}
				}
			>
				{''}
			</ButtonComponent>
		</div>
	) : undefined;
};
