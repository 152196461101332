import axios, { AxiosInstance } from 'axios';
import { AxiosCacheInstance, setupCache } from 'axios-cache-interceptor';

export const apiInstance = (timeout = 100000): AxiosInstance => {
	const axiosInstance = axios.create({
		timeout,
	});

	// add X-Site-Name header to requests
	// const theme = 'CODAN';
	// axios.defaults.headers.common['X-Site-Name'] = theme;

	// Add the error handling interceptor
	axiosInstance.interceptors.response.use(
		(response) => response.data,
		(error) => {
			console.error('API error', error);
			throw error;
		}
	);

	return axiosInstance;
};

let axiosCacheInstance: AxiosCacheInstance;
export const apiInstanceCached = (): AxiosCacheInstance => {
	if (axiosCacheInstance) {
		return axiosCacheInstance;
	}

	const axiosInstance = axios.create({
		timeout: 15000,
	});

	axiosCacheInstance = setupCache(axiosInstance, {
		ttl: 1000 * 60 * 10, // 10 minutes cache
		methods: ['get', 'post'],
		interpretHeader: false, // override no-cache
	});

	// add request interceptor here if needed

	return axiosCacheInstance;
};
