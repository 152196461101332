import { useModal } from 'hooks';
import React from 'react';
import styles from './TooltipComponent.module.scss';

export const TooltipComponent: React.FC<Tooltip> = (tooltip) => {
	const { displayModal } = useModal();
	const { title, subtitle, content } = tooltip;

	return (
		<button
			className={styles.Tooltip}
			onClick={() => displayModal(title, subtitle, content)}
		>
			{tooltip.linkText}
		</button>
	);
};
