import { SearchFieldOption } from 'components/2-molecules/SearchFieldComponent/SearchFieldComponent';
import { InsuranceCompanySearch } from './InsuranceCompanySearch';
import { ZipcodeAddressSearch } from './ZipcodeAddressSearch';

export interface SearchComponent {
	init: () => Promise<SearchFieldOption[]>;
	handleChange?: (option: SearchFieldOption) => Promise<SearchFieldOption>;
	handleInputChange?: (value: string) => Promise<SearchFieldOption[]>;
	apiSearch: boolean;
	minInputLength?: number;
	searchIcon: string;
	searchConfiguration: SearchConfiguration;
}

export enum HttpMethod {
	GET = 'GET',
	POST = 'POST',
	DELETE = 'DELETE',
	PATCH = 'PATCH',
}
export enum FrontendDatasourceEvent {
	INSURANCE_COMPANIES = 'InsuranceCompaniesEvent',
	ZIPCODE = 'ZipcodeEvent',
	ADDRESS = 'AddressEvent',
	CUSTOM_EVENT = 'CustomEvent',
}
interface OutputField {
	content: {
		fieldName: string;
		fieldValueType: 'string' | 'Boolean' | 'Integer';
		contentType: 'SearchFieldonfigurationOutputFieldBlock';
		contentId: number;
	};
}

export interface SearchConfiguration {
	frontendDatasourceEvent?: string;
	httpMethod?: HttpMethod;
	inputFields?: [];
	outputFields?: { items: OutputField[] };
	responseType?: 'obj' | 'lov';
	url?: string;
}

export const getSearchComponent = (searchConfiguration: SearchConfiguration): SearchComponent => {
	if (!searchConfiguration?.frontendDatasourceEvent) {
		return undefined;
	}

	switch (searchConfiguration.frontendDatasourceEvent) {
		case FrontendDatasourceEvent.ADDRESS:
		case FrontendDatasourceEvent.ZIPCODE:
			return new ZipcodeAddressSearch(searchConfiguration);
		case FrontendDatasourceEvent.INSURANCE_COMPANIES:
			return new InsuranceCompanySearch(searchConfiguration);
		case FrontendDatasourceEvent.CUSTOM_EVENT:
			break;
		default:
			break;
	}
};
