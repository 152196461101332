import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CalculatorFormItem {
	data: any;
	page: string;
}

export interface CalculatorFormState {
	[key: string]: CalculatorFormItem;
}

const initialState: CalculatorFormState = {};

export const calculatorFormSlice = createSlice({
	name: 'CalculatorForSlice',
	initialState,
	reducers: {
		addFormItem: (state, action: PayloadAction<{ key: string; item: CalculatorFormItem }>) => {
			const { key, item } = action.payload;
			return {
				...state,
				[key]: item,
			};
		},
		resetFormItem: (state, action: PayloadAction<{ key: string }>) => {
			const { key } = action.payload;
			return {
				...state,
				[key]: undefined,
			};
		},
	},
});

export const { addFormItem, resetFormItem } = calculatorFormSlice.actions;

export default calculatorFormSlice.reducer;
