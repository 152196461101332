import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { apiInstance } from '../../services/Api';

// {
//     "customer_id": "9691310223",
//     "customer_ids": [
//         "9691310223"
//     ],
//     "businessbrand": "339996a3-4df2-4230-bfe9-3051a98500cb",
//     "account_list": [
//         {
//             "account_id": "9691310223",
//             "source_system": "CodanTIA"
//         }
//     ],
//     "tags": "#customer #insurance #CodanTIA",
//     "name": "50R4t7xFsIBszjghHYqIP0bjxqnDBsWS@clients"
// }
export interface CustomerState {
	data: {
		customer_id?: string;
		customer_ids: string[];
		businessbrand?: string;
		account_list: { account_id: string; source_system: string }[];
		tags?: string;
		name?: string;
		isLoggedIn: boolean;
		isDataFetched: boolean;
		// partner?: string;
		// partner_brand?: string;
		// customer_id?: string;
		// phone_number?: string;
		// customer_email?: string;
		// brand_phone_no?: string;
		// usabilla_id?: string;
		[key: string]: any;
	};
	loading?: boolean;
	error?: any;
}

const initialState: CustomerState = {
	data: {
		customer_id: undefined,
		customer_ids: [],
		businessbrand: undefined,
		account_list: [],
		tags: undefined,
		name: undefined,
		isLoggedIn: false,
		isDataFetched: false,
		// partner: undefined,
		// partner_brand: undefined,
		// customer_id: undefined,
		// phone_number: undefined,
		// customer_email: undefined,
		// brand_phone_no: undefined,
		// usabilla_id: undefined,
	},
};

export const loadCustomer = createAsyncThunk('token/validateToken', async (arg, { rejectWithValue }) => {
	try {
		//return await getCustomerDetails();
		//customerNo -> 9691310223

		// Doesn't work(?) const isLoggedIn = await apiInstance(1000).get('/api/v1/auth/tokenexpire');

		const customer = await apiInstance(10000).get('/api/v1/serviceproxy/customer/details');
		return customer;
	} catch (error) {
		const formattedError = error as AxiosError;
		if (!formattedError?.response) {
			throw error;
		}
		const { data, status } = formattedError.response;
		return rejectWithValue({ data, status });
	}
});

export const customerSlice = createSlice({
	name: 'CustomerSlice',
	initialState,
	reducers: {
		setCustomer: (state, action: PayloadAction<any>) => {
			const fieldValues = action.payload;
			if (!fieldValues) return state;

			state.data = { ...state.data, ...fieldValues };
		},

		resetCustomer: (state) => {
			const { isUserLoggedIn, customer_id } = state.data;
			state.data = { ...initialState.data, isUserLoggedIn, customer_id };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(loadCustomer.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(loadCustomer.fulfilled, (state) => {
			state.loading = false;
			state.data.isLoggedIn = true;
			state.data.isDataFetched = true;
			state.error = null;
		});
		builder.addCase(loadCustomer.rejected, (state, action) => {
			state.loading = false;
			state.data.isLoggedIn = false;
			state.data.isDataFetched = true;
			state.error = action.payload;
		});
	},
});
export const { setCustomer, resetCustomer } = customerSlice.actions;

export default customerSlice.reducer;
