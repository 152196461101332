import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { memo, useContext, useEffect, useState } from 'react';
import {
	getSearchComponent,
	SearchComponent,
	SearchConfiguration,
} from '../../../searchConfiguration/SearchConfiguration';
import { SelectField, SelectFieldProps } from './SelectField/SelectField';

export interface SearchFieldOption {
	label: string;
	value: string;
}

export interface SearchFieldComponentProps extends SelectFieldProps<ThemeUnion> {
	register: any;
	contentType: string;
	stepContentId: string;
	submitStep?: () => void;
	placeholderText?: string;
	searchConfiguration?: SearchConfiguration;
}

export const SearchFieldComponent: React.FC<SearchFieldComponentProps> = memo(
	({
		register,
		submitStep,
		contentType,
		stepContentId,
		searchConfiguration,
		isSearchable = true,
		placeholderText,
		...props
	}) => {
		const pageContext = useContext(PageContext);
		const { theme } = pageContext ?? {};
		const themeName = ThemeNames[theme];

		const { onChange, name } = register(stepContentId.toString(), { required: true });

		const [searchResult, setSearchResult] = useState<SearchFieldOption[]>();
		const [searchComponent, setSearchComponent] = useState<SearchComponent>();

		// handle search result menu when API search
		const [handleMenu, setHandleMenu] = useState<boolean>(undefined);

		// naming from cms wrong
		props.placeHolder = placeholderText;

		useEffect(() => {
			setSearchComponent(getSearchComponent(searchConfiguration));
		}, []);

		useEffect(() => {
			const init = async () => {
				if (searchComponent && !searchResult) {
					if (searchComponent.apiSearch) {
						setHandleMenu(false);
					}
					const options: SearchFieldOption[] = await searchComponent.init();
					setSearchResult(options);
				}
			};
			init();
		}, [searchComponent]);

		const handleChange = (option: SearchFieldOption) => {
			const call = async () => {
				const modifiedOption = searchComponent.handleChange
					? await searchComponent.handleChange(option)
					: option;

				onChange({
					target: {
						name,
						value: {
							id: name,
							label: modifiedOption.label,
							value: modifiedOption.value,
							type: contentType,
						},
					},
				});
				submitStep && submitStep();
			};
			call();
		};

		// only API search
		const inputChange = (value: string) => {
			const call = async () => {
				const options: SearchFieldOption[] = await searchComponent.handleInputChange(value);
				// undefined options indicates search aborted, otherwise it'll be minimum []
				if (options) {
					setSearchResult(options);
				}
				setHandleMenu(value?.trim().length >= searchComponent.minInputLength);
			};
			call();
		};

		return (
			<SelectField
				icon={{ iconProp: { themeName, icon: searchComponent?.searchIcon } }}
				options={searchResult}
				{...props}
				onChange={handleChange}
				onInputChange={searchComponent?.apiSearch ? inputChange : undefined}
				filterOptions={searchComponent?.apiSearch ? 'ignore' : undefined}
				menuIsOpen={handleMenu}
				errorMessage={props.errorMessage ?? 'Vælg en værdi'}
			/>
		);
	}
);
SearchFieldComponent.displayName = 'SearchFieldComponent';
