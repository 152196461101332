import { Headline } from '@almbrand/headline';
import { RichText } from '@almbrand/richtext';
import { useAppDispatch } from 'store/hooks';
import { setModal } from 'store/slices/modalSlice';

export const useModal = () => {
	const dispatch = useAppDispatch();

	const displayErrorModal = (title: string, subtitle: string, content: string) => {
		if (title) {
			dispatch(
				setModal({
					displayModal: true,
					modalProps: {
						title,
						children: (
							<>
								<div style={{ marginBottom: 'var(--spacing--md)' }}>
									<Headline
										fontColor='primary'
										size='default'
										title={subtitle}
										variant='h2'
									/>
								</div>
								<div style={{ color: 'red' }}>{content}</div>
							</>
						),
						handleClose: () => dispatch(setModal({ displayModal: false })),
					},
				})
			);
		}
	};

	const displayModal = (title: string, subtitle: string, content: string) => {
		if (title) {
			dispatch(
				setModal({
					displayModal: true,
					modalProps: {
						title,
						children: (
							<>
								{subtitle && (
									<div style={{ marginBottom: 'var(--spacing--md)' }}>
										<Headline
											fontColor='primary'
											size='default'
											title={subtitle}
											variant='h2'
										/>
									</div>
								)}
								<RichText description={content} />
							</>
						),
						handleClose: () => dispatch(setModal({ displayModal: false })),
					},
				})
			);
		}
	};

	return { displayErrorModal, displayModal };
};
