import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loadCustomer, setCustomer } from 'store/slices/customerSlice';

export const useFetchCustomer = () => {
	const dispatch = useAppDispatch();
	const customer = useAppSelector((state) => state.customer);

	useEffect(() => {
		const call = async () => {
			if (!customer.data.isDataFetched && !customer.loading) {
				const authResult = await dispatch(loadCustomer());
				if (authResult.meta.requestStatus === 'fulfilled') {
					dispatch(setCustomer(authResult.payload));
				}
			}
		};
		call();
	}, [customer.data.isDataFetched]);
};
