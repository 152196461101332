const formatAmount_: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

const formatNumber_: Intl.NumberFormat = Intl.NumberFormat('da-DK', {
	minimumFractionDigits: 0,
	maximumFractionDigits: 0,
});

export const formatNumber = (num: number): string => {
	return isNaN(num) ? undefined : formatNumber_.format(num);
};

export const formatAmount = (num: number): string => {
	return isNaN(num) ? undefined : formatAmount_.format(num);
};

export const formatDkDate = (date = new Date()) => {
	const dateFormatter = Intl.DateTimeFormat('da-DK', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	return dateFormatter.format(date).replaceAll('.', '-');
};

export const formatShortIsoDate = (date = new Date()) => {
	return date.toISOString().split('T')[0];
};
