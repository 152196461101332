import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addFormItem, resetFormItem } from 'store/slices/calculatorFormSlice';

export const useFormSubmit = () => {
	const dispatch = useAppDispatch();

	const onSubmit = async (fromData: any) => {
		if (fromData) {
			Object.keys(fromData).forEach((key, index) => {
				dispatch(
					addFormItem({
						key: key,
						item: { data: fromData[key], page: index.toString() },
					})
				);
			});
		}
	};

	return { onSubmit };
};

export const useFormFieldReset = () => {
	const dispatch = useAppDispatch();
	const form = useAppSelector((state) => state.calculatorForm);
	const resetFormField = (formFieldId: string) => {
		if (form[formFieldId]?.data) {
			dispatch(
				resetFormItem({
					key: formFieldId,
				})
			);
		}
	};

	return { resetFormField };
};
