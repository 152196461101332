import { DatePicker, DatePickerProps } from '@almbrand/datepicker';
import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { useContext } from 'react';

export interface DatePickerComponentProps extends DatePickerProps<ThemeUnion> {
	register: any;
	name: string;
	contentType: string;
	submitStep?: () => void;
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({
	register,
	name,
	contentType,
	submitStep,
	...props
}) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];

	const { onChange, name: datePickerComponentName } = register(name, { required: true });

	const updatedComponentProps = {
		...props,
		calculateValidRange: true,
	};

	const handleOnChange = (value: Date) => {
		onChange({
			target: {
				name: datePickerComponentName,
				value: {
					id: name,
					value,
					type: contentType,
				},
			},
		});
		submitStep && submitStep();
	};

	return (
		<DatePicker
			{...updatedComponentProps}
			icon={{ iconProp: { themeName, icon: 'calendar' } }}
			onDateChange={handleOnChange}
		/>
	);
};
