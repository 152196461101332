import { ModalProps } from '@almbrand/modal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface ModalState {
	displayModal: boolean;
	modalProps?: ModalProps;
}

const initialState: ModalState = {
	displayModal: false,
	modalProps: undefined,
};

export const modalSlice = createSlice({
	name: 'ModalSlice',
	initialState,
	reducers: {
		setModal: (state, action: PayloadAction<{ displayModal: boolean; modalProps?: ModalProps }>) => {
			const { displayModal, modalProps } = action.payload;

			return {
				displayModal: displayModal,
				modalProps: modalProps,
			};
		},
	},
});

export const { setModal } = modalSlice.actions;
export default modalSlice.reducer;
