import { store } from 'store/store';

export const getActivePage = (pageId: number): StepBlock => {
	const contentAreas = store.getState().pages.contentArea;
	let stepBlock: StepBlock;
	Object.keys(contentAreas).forEach((area) => {
		if (!stepBlock && contentAreas[area]?.content?.activePages?.length > 0) {
			contentAreas[area].content.activePages.forEach((step) => {
				if (step.contentId === pageId) {
					stepBlock = { ...step }; // copy
				}
			});
		}
	});
	return stepBlock;
};
