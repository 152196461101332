import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
// import App from './MockApp';
import App from './App';
import { store } from './store';

const app = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

app.render(
	<Provider store={store}>
		<Router>
			{/* <StrictMode> */}
			<App />
			{/* </StrictMode> */}
		</Router>
	</Provider>
);
