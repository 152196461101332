import { RichText } from '@almbrand/richtext';
import { SystemIcons } from '@almbrand/systemicons';
import classNames from 'classnames';
import { useState } from 'react';
import styles from './ToggleLink.module.scss';

export interface ToggleLinkProps {
	linkText: string;
	closeLinkText?: string;
	toggleContentText?: string;
	toggleContentClassname?: string;
}

export const ToggleLink: React.FC<ToggleLinkProps> = ({
	linkText,
	closeLinkText = linkText,
	toggleContentText,
	toggleContentClassname = 'NA',
}) => {
	const [open, setOpen] = useState(false);

	const handleClick = (event) => {
		event.preventDefault();
		setOpen(!open);
	};

	return (
		<>
			<div
				className={classNames(styles.ToggleLink)}
				onClick={handleClick}
			>
				<span className={styles.label}>{open ? linkText : closeLinkText}</span>
				<SystemIcons
					className={classNames(styles.Chevron, open && styles.open)}
					themeName={'Codan-theme'}
					icon={'arrow_down'}
				/>
			</div>
			{open && (
				<RichText
					description={toggleContentText}
					className={toggleContentClassname}
				/>
			)}
		</>
	);
};
