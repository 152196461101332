import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface RadioCardState {
	index?: number;
	name?: string;
}

const initialState: RadioCardState[] = [];

const radioCardSlice = createSlice({
	name: 'RadioCardSlice',
	initialState,
	reducers: {
		setCurrentRadioState: (state, action: PayloadAction<RadioCardState>) => {
			const card: RadioCardState = state.find((card) => card.name === action.payload.name);

			if (card) {
				card.index = action.payload.index;
			} else {
				return [...state, { index: action.payload.index, name: action.payload.name }];
			}
		},
		removeRadioState: (state, action: PayloadAction<string>) => {
			return [...state.filter((card) => card.name !== action.payload)];
		},
	},
});

export const { setCurrentRadioState, removeRadioState } = radioCardSlice.actions;
export default radioCardSlice.reducer;
