import { InputTextField, InputTextFieldProps } from '@almbrand/inputtextfield';
import { ThemeUnion } from '@almbrand/systemicons/dist/cjs/types/icon';
import { ThemeNames } from 'constantsValues';
import { PageContext } from 'context/PageContext';
import { useContext, useEffect, useRef, useState } from 'react';
import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';
import styles from './InputTextFieldComponent.module.scss';

export interface InputTextFieldComponentProps extends InputTextFieldProps<ThemeUnion> {
	register: UseFormRegister<FieldValues>;
	contentType: string;
	stepContentId: number;
	isValid: boolean;
}

export const InputTextFieldComponent: React.FC<InputTextFieldComponentProps> = ({
	register,
	stepContentId,
	contentType,
	...props
}) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];

	const { resetField } = useFormContext(); // retrieve hook methods
	const input = useRef(null);

	const [hasError, setHasError] = useState(props.hasError);
	const updatedComponentProps = {
		...props,
		...register(props.name, {
			required: props.required,
			pattern: new RegExp(props.pattern),
		}),
		icon: { iconProp: { themeName } } as any,
	};

	const { onBlur, onChange, name } = register(stepContentId.toString());

	const invalidateField = () => {
		resetField(name);
		setHasError(true);
	};

	const isValid = (value) => {
		switch (updatedComponentProps.type) {
			case 'number':
				if (value >= (updatedComponentProps.min as number) && value <= (updatedComponentProps.max as number)) {
					return true;
				}
				return false;
			case 'text':
				if (
					value.length >= (updatedComponentProps.min as number) &&
					value.length <= (updatedComponentProps.max as number)
				) {
					return true;
				}
				return false;
			default:
				return value?.length > 0;
		}
	};

	const handleOnBlur = (evt) => {
		if (!isValid(evt.target.value)) {
			invalidateField();
			return;
		}
		setHasError(false);

		onBlur({
			target: {
				name,
				value: {
					id: name,
					value: evt.target?.value,
					type: contentType,
				},
			},
		});
	};

	const handleOnChange = (evt) => {
		if (!isValid(evt.target.value)) {
			if (updatedComponentProps.isValid) {
				invalidateField();
			}
		} else {
			setHasError(false);
			updatedComponentProps.isValid = true;
			onChange({
				target: {
					name,
					value: {
						id: name,
						value: evt.target?.value,
						type: contentType,
					},
				},
			});
		}
	};

	useEffect(() => {
		// don't show form error bubble on input field
		if (!input.current) {
			return;
		}
		const hideErrorBubble = (evt) => {
			setHasError(true);
			evt.preventDefault();
		};
		input.current.addEventListener('invalid', hideErrorBubble);
		return () => {
			if (input.current) {
				input.current.removeEventListener('invalid', hideErrorBubble);
			}
		};
	}, [input.current]);

	return (
		<div className={styles.InputTextFieldComponent}>
			<InputTextField
				{...updatedComponentProps}
				onBlur={handleOnBlur}
				onChange={handleOnChange}
				hasError={hasError}
				ref={input}
			/>
		</div>
	);
};
