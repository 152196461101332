import { store } from 'store/store';

const DoubleCurlyRegex = /{{(.+?)}}/g;

const enum FIELD_PROPERTY {
	TITLE = 'title',
	LABEL = 'label',
	CONTENT = 'content',
}

export const replacePlaceholders = (field: FieldsBlock) => {
	switch (field?.contentType) {
		case 'RadioCardFieldBlock':
			replacePlaceholderByType(field, FIELD_PROPERTY.TITLE);
			break;
		case 'CheckboxFieldBlock':
			replacePlaceholderByType(field, FIELD_PROPERTY.LABEL);
			break;
		case 'TeaserCardBlock':
			replacePlaceholderByType(field, FIELD_PROPERTY.CONTENT);
			break;

		default:
			return;
	}
};

const replacePlaceholderByType = (field: FieldsBlock, propertyType: FIELD_PROPERTY) => {
	if (field?.[propertyType]) {
		const matches = field[propertyType].match(DoubleCurlyRegex);
		if (matches?.length > 0) {
			field[propertyType] = replace(field[propertyType], matches);
		}
	}
};

const replace = (value: string, matches: string[]) => {
	let result = value;
	let regMatch: RegExp;
	let replacement: string;
	const definitions = store.getState().placeholderDefinitions.definitions;
	const form = store.getState().calculatorForm;
	// expand to also search in other parts of the store (i.e customer)

	matches.forEach((match) => {
		regMatch = new RegExp(match, 'gi');
		replacement = getPlaceholderReplacement(match.toLowerCase(), definitions, form);
		result = result.replace(regMatch, replacement).trim();
	});
	return result;
};

const getPlaceholderReplacement = (placeholder: string, definitions, form) => {
	const value = form[definitions[placeholder]]?.data?.value;
	return value ?? `!NA${placeholder}`;
};
