import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CalculationItem {
	price: any;
}

export interface CalculationState {
	[key: string]: CalculationItem;
}

const initialState: CalculationState = {};

export const calculationSlice = createSlice({
	name: 'CalculationSlice',
	initialState,
	reducers: {
		addCalculationItem: (state, action: PayloadAction<{ key: string; item: CalculationItem }>) => {
			const { key, item } = action.payload;
			return {
				...state,
				[key]: item,
			};
		},
	},
});

export const { addCalculationItem } = calculationSlice.actions;

export default calculationSlice.reducer;
