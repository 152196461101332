import { useAppDispatch, useAppSelector } from 'store/hooks';
import { loadNewPage } from 'store/slices/pageListSlice';
import { isPageHidden } from '../services/DependencyService';
import { getActivePage } from '../services/PageService';
import { useFormFieldReset } from './useFormSubmit';
import { usePageParam } from './usePageParam';

export const useNavigation = () => {
	const dispatch = useAppDispatch();
	const { orderedPageList } = useAppSelector((state) => state.pages);
	const { setPageParam } = usePageParam();

	const { resetFormField } = useFormFieldReset();

	const isStepHidden = (pageId: number, forward: boolean) => {
		const activePage = getActivePage(pageId);
		const isHidden = isPageHidden(activePage);
		if (forward && isHidden) {
			// reset formField values on hidden pages when navigation forward
			activePage.fields?.items?.forEach((field) => {
				const formFieldId = field?.content?.uniqueIdentifier?.uid || field?.content?.name;
				// fields will still have value on the react-hook-form but not in the store
				resetFormField(formFieldId);
			});
		}
		return isHidden;
	};

	const navigate = (pageId: number, forward: boolean) => {
		const currentInx = orderedPageList.indexOf(pageId);
		let pageList = [...orderedPageList];

		if (forward) {
			pageList = pageList.splice(currentInx + 1);
		} else {
			pageList = pageList.splice(0, currentInx);
			pageList.reverse();
		}

		const navigateTo = pageList.find((id) => !isStepHidden(id, forward));

		navigateTo ? setPageParam(navigateTo.toString()) : dispatch(loadNewPage());
	};
	return { navigate };
};
