import { combineReducers } from '@reduxjs/toolkit';
import { DESTROY_SESSION } from './actionTypeConstants';

import calculationSlice, { CalculationState } from './slices/calculationSlice';
import calculatorFormSlice, { CalculatorFormState } from './slices/calculatorFormSlice';
import customerSlice, { CustomerState } from './slices/customerSlice';
import modalSlice, { ModalState } from './slices/modalSlice';
import pageListSlice, { PageListState } from './slices/pageListSlice';
import placeholderSlice, { PlaceholderState } from './slices/placeholderSlice';
import radioCardSlice, { RadioCardState } from './slices/radioCardSlice';

export interface State {
	pages: PageListState;
	currentRadioState: RadioCardState[];
	calculatorForm: CalculatorFormState;
	calculationState: CalculationState;
	customer: CustomerState;
	modal: ModalState;
	placeholderDefinitions: PlaceholderState;
}

// Combine all reducers.
const appReducer = combineReducers({
	pages: pageListSlice,
	currentRadioState: radioCardSlice,
	calculatorForm: calculatorFormSlice,
	calculationState: calculationSlice,
	customer: customerSlice,
	modal: modalSlice,
	placeholderDefinitions: placeholderSlice,
});

export const rootReducer = (state: State | undefined, action: any) => {
	// Clear all data in redux store to initial.
	if (action.type === DESTROY_SESSION) state = undefined;

	return appReducer(state, action);
};
export default rootReducer;
