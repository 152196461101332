import { ButtonComponent } from 'components/1-atoms';
import { ButtonComponentProps } from 'components/1-atoms/ButtonComponent/ButtonComponent';
import { ThemeNames } from 'constantsValues';
import { IsPackageComponentContext } from 'context/IsPackageComponentContext';
import { PageContext } from 'context/PageContext';
import { useFormSubmit, useNavigation } from 'hooks';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatNumber } from '../../../../utilities/format';
import styles from './SelectPackageButton.module.scss';

export interface SelectPackageButtonProps {
	header: {
		title: string;
	};
	setSelected: (selected: boolean) => void;
	isSelected?: boolean;
	priceDiscount?: number;
	isLoading?: boolean;
	pageId: number;
}

export const SelectPackageButton: React.FC<SelectPackageButtonProps> = ({
	header,
	setSelected,
	isSelected,
	priceDiscount,
	isLoading,
	pageId,
}) => {
	const pageContext = useContext(PageContext);
	const { theme } = pageContext ?? {};
	const themeName = ThemeNames[theme];

	const methods = useForm({ mode: 'onTouched' });
	const { handleSubmit, register } = methods;

	const { setIsVisible } = useContext(IsPackageComponentContext);

	const { onSubmit } = useFormSubmit();

	const { navigate } = useNavigation();

	const buttonProps: ButtonComponentProps = {
		isValid: !isLoading,
		type: 'submit',
		buttonType: isSelected ? 'secondary' : 'primary',
		label: isSelected ? 'Valgt' : 'Vælg ' + header.title,
		children: undefined,
	};

	const handleOnChange = async () => {
		if (!isLoading) {
			const { onChange, name } = register('SELECTED_PACKAGE');
			onChange({
				target: {
					name,
					value: {
						id: header.title,
						value: formatNumber(priceDiscount),
						type: 'PACKAGE',
					},
				},
			});

			const yearlyPrice = priceDiscount * 12 * 0.97;
			const savings = priceDiscount * 12 - yearlyPrice;
			onSubmit({
				SELECTED_PACKAGE_MONTHLY_PRICE: {
					id: 'SELECTED_PACKAGE_MONTHLY_PRICE',
					value: formatNumber(priceDiscount),
					index: 0,
					type: 'PACKAGE',
				},
			});
			onSubmit({
				SELECTED_PACKAGE_YEARLY_PRICE: {
					id: 'SELECTED_PACKAGE_YEARLY_PRICE',
					value: formatNumber(yearlyPrice),
					index: 0,
					type: 'PACKAGE',
				},
			});
			onSubmit({
				SELECTED_PACKAGE_YEARLY_SAVINGS: {
					id: 'SELECTED_PACKAGE_YEARLY_SAVINGS',
					value: formatNumber(savings),
					index: 0,
					type: 'PACKAGE',
				},
			});

			setSelected(true);

			await handleSubmit(onSubmit)();
		}
	};

	const handleClick = async () => {
		if (!isLoading) {
			await handleOnChange();
			navigate(pageId, true);
			// hack to handle package styles
			setTimeout(() => {
				setIsVisible(false);
			}, 100);
		}
	};

	useEffect(() => {
		if (isSelected && priceDiscount) {
			handleOnChange();
		}
	}, [priceDiscount]);

	return (
		<div className={styles.SelectPackageButton}>
			<ButtonComponent
				{...buttonProps}
				isValid={!isLoading}
				onClick={handleClick}
				iconProps={
					isSelected && {
						themeName,
						icon: 'check',
						height: '40',
						width: '40',
					}
				}
			/>
		</div>
	);
};
