import { createContext, useMemo, useState } from 'react';

interface IsPackageComponentContextType {
	isVisible: boolean;
	setIsVisible: (value: boolean) => void;
}

export const IsPackageComponentContext = createContext<IsPackageComponentContextType | undefined>(undefined);

export const IsPackageComponentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);
	const contextValue = useMemo(() => ({ isVisible, setIsVisible }), [isVisible]);

	return <IsPackageComponentContext.Provider value={contextValue}>{children}</IsPackageComponentContext.Provider>;
};
