import { CalculatorFormState } from 'store/slices/calculatorFormSlice';
import { store } from 'store/store';

export const isFieldHidden = (field: FieldsContent): boolean => {
	return isHidden(field?.content?.contentDependency);
};
export const isPageHidden = (page: StepBlock): boolean => {
	const isHidden_ = isHidden(page?.contentDependency);
	if (!isHidden_) {
		// check that all fields are not hidden
		const visibleFields = page?.fields?.items?.some((field) => !isFieldHidden(field));
		return !visibleFields;
	}
	return isHidden_;
};

const isHidden = (dependency: ContentDependency) => {
	if (dependency?.dependsOn?.uid) {
		const form = store.getState().calculatorForm;

		switch (dependency.dependCondition) {
			case 'NotApplicable':
				return existDependency(dependency, form);
			case 'Equals':
				return equals(dependency, form);
			case 'NotEquals':
				return !equals(dependency, form);
			case 'MatchRegularExpression': // implement
				return false;
			case 'Contains': // implement
				return false;
			case 'NotContains': // implement
				return false;
			default:
				return false;
		}
	}
	return false;
};

const existDependency = (dependency: ContentDependency, form: CalculatorFormState): boolean => {
	const dependsOnId = dependency.dependsOn.uid;
	const dependentValue = form[dependsOnId]?.data?.value;
	return dependentValue === undefined || dependentValue === null;
};

const equals = (dependency: ContentDependency, form: CalculatorFormState): boolean => {
	const dependsOnId = dependency.dependsOn.uid;
	const dependentValue = form[dependsOnId]?.data?.value;
	return dependency.dependValue !== dependentValue;
};
